export const capitalize = (input: string) => {
	const tokens = input
		.replaceAll(/([A-Z])/g, ' $1')
		.replaceAll('-', ' ')
		.split(' ')
	const caps = tokens.map(
		(t) => t.slice(0, 1).toUpperCase() + t.slice(1).toLowerCase(),
	)
	return caps.join(' ')
}
